import React from 'react';

import './Spinner.scss';

export const Spinner = ({ small }) => {
  return (
    <div className={small ? 'spinner-small' : 'spinner-large'}>
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};
