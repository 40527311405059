import { KeyboardDatePicker as _KeyboardDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

const KeyboardDatePicker = styled(_KeyboardDatePicker)({
  '& .MuiIconButton-root': {
    padding: '0 !important',
  },
});

export const Datepicker = ({ children, ...props }) => {
  return <KeyboardDatePicker {...props} />;
};
