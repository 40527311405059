import React from 'react';
import { Box, CssBaseline } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../theme';
import { Drawer, Navbar } from '.';

const drawerWidth = 340;

const Root = styled(Box)({
  display: 'flex',
});

const Main = styled('main')({
  width: '100%',
});

const DrawerHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
});

export const Navigation = ({ children }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      <CssBaseline />
      <Navbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Main>
        <DrawerHeader />
        {children}
      </Main>
      <Drawer
        handleDrawerClose={handleDrawerClose}
        open={open}
        drawerWidth={drawerWidth}
      />
    </Root>
  );
};
