export const notificationType = {
  unspecifiedTrip: 'unspecifiedTrip',
  vehicleCheckup: 'vehicleCheckup',
  vehicleTax: 'vehicleTax',
  companyInvoice: 'companyInvoice',
};

const UNSPECIFIED_TRIP = 'NEW_UNSPECIFIED_TRIP';
const VEHICLE_CHECKUP = 'NEW_VEHICLE_CHECKUP';
const VEHICLE_TAX = 'VEHICLE_TAX';
const COMPANY_INVOICE = 'COMPANY_INVOICE';
const UNSPECIFIED_TRIP_REMOVE_MULTIPLE = 'UNSPECIFIED_TRIP_REMOVE_MULTIPLE';

export {
  UNSPECIFIED_TRIP,
  VEHICLE_CHECKUP,
  VEHICLE_TAX,
  COMPANY_INVOICE,
  UNSPECIFIED_TRIP_REMOVE_MULTIPLE,
};
