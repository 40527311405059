import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, Typography } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './ui/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Provider from './store/auth';
import Status from './hoc/Status/Status';
import { SnackbarUtilsConfigurator } from './ui/SnackbarUtils';
import { ConfirmProvider } from 'material-ui-confirm';
import './i18n';

ReactDOM.render(
  <Router>
    <Provider>
      <SnackbarProvider domRoot={document.getElementById('react-notification')}>
        <SnackbarUtilsConfigurator />
        <Status>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ConfirmProvider
              defaultOptions={{
                // Are you sure Replace With Är du säker
                title: <Typography variant="h5">Är du säker?</Typography>,
                confirmationText: 'OK',
                cancellationText: 'Avbryt',
                confirmationButtonProps: {
                  variant: 'contained',
                  color: 'primary',
                },
                cancellationButtonProps: {
                  variant: 'outlined',
                  color: 'primary',
                },
              }}
            >
              <App />
            </ConfirmProvider>
          </ThemeProvider>
        </Status>
      </SnackbarProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
