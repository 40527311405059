import { Box, Button, FormControl, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import styled from 'styled-components';
import theme from '../../ui/theme';
import LogoImg from '../../assets/images/logo.png';
import TrafficImg from '../../assets/images/traffic.jpg';
import { useHistory, useParams } from 'react-router-dom';
import { changePasswordRequest } from 'http/requests';
import { changePasswordValidationSchema } from 'validation';
import { SnackbarUtils } from 'ui';
import { clearToken } from 'utils/jsonwebtoken';

const Container = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'center',
  background: `url('${TrafficImg}')`,
  backgroundSize: 'cover',
  padding: theme.spacing(1),
  minHeight: 'calc(100vh - 64px)',
  maxHeight: 'calc(100vh - 64px)',
});

const FormHeader = styled(Box)({
  textAlign: 'center',
  padding: theme.spacing(1),
});

const Form = styled('form')({
  padding: theme.spacing(3, 2),
  borderRadius: '3px',
  width: '424px',
  maxWidth: '100%',
  backgroundColor: '#ffffff',
});

const LoginButton = styled(Button)({
  margin: theme.spacing(2, 0),
});

const Logo = styled('img')({
  width: '100%',
  marginBottom: theme.spacing(2),
});

// const validationSchema = yup.object({
//   //email: yup.string().email('Email is not valid').required('Email is required'),
//   password: yup.string().required('Password is required'),
// });

const ResetPassword = () => {
  const history = useHistory();
  const { id, token } = useParams();
  // clearToken();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await changePasswordRequest({
          ...values,
          token: token,
          userId: id,
        });

        SnackbarUtils.success(response.data.message);
        resetForm();
        history.push('/login');
      } catch (error) {
        SnackbarUtils.error(error.response.data.message);
        resetForm();
      } finally {
      }
    },
  });

  return (
    <Container container>
      <Form onSubmit={formik.handleSubmit}>
        <FormHeader>
          <Logo src={LogoImg} alt="Trackmondo" />
        </FormHeader>
        <FormControl variant="outlined" fullWidth>
          <TextField
            name="password"
            id="password"
            label="Lösenord" //Password change to Lösenord
            variant="outlined"
            margin="normal"
            size="small"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            autoComplete="off"
            fullWidth
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            name="confirmPassword"
            id="confirmPassword"
            label="Bekräfta lösenord" //Confirm Password replaced with Bekräfta lösenord
            variant="outlined"
            margin="normal"
            size="small"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            autoComplete="off"
            fullWidth
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </FormControl>
        <FormControl fullWidth>
          {/* Reset Password replaced with Återställ lösenord */}
          <LoginButton
            variant="outlined"
            color="primary"
            type="submit"
            fullWidth
          >
            Återställ lösenord
          </LoginButton>
        </FormControl>
      </Form>
    </Container>
  );
};

export default ResetPassword;
