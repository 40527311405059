import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Grid,
  Button as _Button,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../ui/theme';
import { changeTripTypeRequest, fetchTripRequest } from 'http/requests';
import { Loader, SnackbarUtils } from 'ui';

const Form = styled('form')({});

const Button = styled(_Button)({
  margin: theme.spacing(1, 1, 0, 0),
});

const validationSchema = yup.object({});

export const ChangeType = (props) => {
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState({
    tripType: 'unspecified',
    note: '',
  });
  const { onClose, selectedTripolog, handleChangeTripType, handleUpdatedList } =
    props;

  const fetchVehicles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchTripRequest({
        id: selectedTripolog.content.tripId,
      });

      setTrip({
        ...trip,
        ['tripType']: response.data.trip.tripType,
        ['note']: response.data.trip.note,
        ...response.data.trip,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchVehicles();
  }, []);
  const formik = useFormik({
    initialValues: {
      tripType: trip?.tripType,
      note: trip?.note,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await changeTripTypeRequest({
          ...values,
          id: trip.id,
          notificationId: selectedTripolog.id,
        });
        handleChangeTripType({ ...values, id: trip.id });
        handleUpdatedList({ id: selectedTripolog.id });
        SnackbarUtils.success(response.data.message);
      } catch (error) {
        SnackbarUtils.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <Loader loading={loading}>
      <Box p={3}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Box p={1}>
                <RadioGroup
                  aria-label="trip-type"
                  name="tripType"
                  value={formik.values.tripType}
                  onChange={formik.handleChange}
                >
                  <Box p={1}>
                    <FormControlLabel
                      value="business"
                      control={<Radio color="primary" size="small" />}
                      label="Tjänst" //Business Word Replace to Tjänst
                    />
                  </Box>
                  <Box p={1}>
                    <FormControlLabel
                      value="private"
                      control={<Radio color="primary" size="small" />}
                      label="Privat" //Private Trip  Word Replace to Privat
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    name="note"
                    id="note"
                    label="Notering"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    //autoComplete="off"
                    fullWidth
                    multiline
                    rows={4}
                    maxRows={30}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                {/* Save replaced with Spara */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    formik.values.tripType !== 'unspecified' ? false : true
                  }
                >
                  Spara
                </Button>
                {/* Cancel replaced with Avbryt */}
                <Button variant="outlined" color="primary" onClick={onClose}>
                  Avbryt
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Loader>
  );
};
