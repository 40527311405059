import { Switch, Route } from 'react-router-dom';
import { Suspense } from 'react';
import { useAuthContext } from 'store/auth';
import { Navigation, Spinner } from 'ui';
import { routeListing } from './listing';
import { RoleBased } from 'utils/RoleBased';
import { IF } from 'utils/IF';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';

const Routes = () => {
  const { isLoggedin } = useAuthContext();

  const routes = (
    <Navigation>
      <Switch>
        {routeListing.map((route) => {
          const { path, component, auth, roles } = route;

          return (
            <Route path={path} exact key={path}>
              <IF condition={isLoggedin === auth}>
                <RoleBased {...roles}>{component}</RoleBased>
              </IF>
            </Route>
          );
        })}
        <Route
          exact
          path="/reset-password/:id/:token"
          component={ResetPassword}
        />
      </Switch>
    </Navigation>
  );

  return <Suspense fallback={<Spinner />}>{routes}</Suspense>;
};

export default Routes;
