import { useAuthContext } from '../store/auth';

const containsRole = (roles, role) => {
  return roles?.find((el) => el.id === role)?.name;
};

export const RoleBased = ({
  SUPER_ADMIN,
  RESELLER,
  ADMIN,
  DRIVER,
  COMPANY,
  children,
}) => {
  const {
    user: { role },
  } = useAuthContext();
  const { roles } = useAuthContext();

  if (containsRole(roles, role) === SUPER_ADMIN) {
    return children;
  }

  if (containsRole(roles, role) === RESELLER) {
    return children;
  }

  if (containsRole(roles, role) === ADMIN) {
    return children;
  }

  if (containsRole(roles, role) === DRIVER) {
    return children;
  }

  if (containsRole(roles, role) === COMPANY) {
    return children;
  }

  if (!SUPER_ADMIN && !RESELLER && !ADMIN && !DRIVER && !COMPANY) {
    return children;
  }

  return null;
};
