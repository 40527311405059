 import axios from 'axios';

const httpRefresh = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SERVER_URL_DEV
      : process.env.REACT_APP_SERVER_URL_PROD,
  withCredentials: true,
});

export default httpRefresh;
