import { Box, TableContainer as _TableContainer } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../theme';

export const TableWrapper = styled(Box)({
  padding: theme.spacing(3),
  '@media(max-width: 768px)': {
    padding: theme.spacing(3, 0),
  },
});

export const TableContainer = styled(_TableContainer)({
  maxHeight: 'calc(100vh - 220px)',
});
