import jwt_decode from 'jwt-decode';
export const isTokenValid = () => {
  const token = window.localStorage.getItem('accessToken');

  try {
    jwt_decode(token);
  } catch (error) {
    return false;
  }

  return true;
};

export const jwtDecode = () => {
  const accessToken = window.localStorage.getItem('accessToken');
  return jwt_decode(accessToken);
};

export const clearToken = () => {
  window.localStorage.removeItem('accessToken');
};

export const addToken = (accessToken) => {
  window.localStorage.setItem('accessToken', accessToken);
};
