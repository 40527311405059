import React from 'react';
import {
  Backdrop,
  Box,
  Card,
  Fade,
  IconButton,
  Modal as _Modal,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { IF } from 'utils/IF';
import { Title } from '../';
import styled from 'styled-components';
import theme from '../theme';

const ModalPaper = styled(Box)(({ width, title }) => ({
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  padding: title ? theme.spacing(0, 0, 4, 0) : theme.spacing(6, 0, 4, 0),
  borderRadius: theme.spacing(1),
  margin: theme.spacing(1),
  backgroundColor: '#ffffff',
  width: width ? width : '420px',
  position: 'relative',
  maxHeight: 'calc(100vh - 120px)',
  overflowY: 'auto',
}));

const Modal = styled(_Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CloseModalIcon = styled(IconButton)({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
});

const TitleWrapper = styled(Card)({
  padding: theme.spacing(2, 4, 2, 3),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
});

export const MuiModal = ({
  children,
  onClose,
  open,
  width,
  title,
  showCloseIcon,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalPaper width={width} title={title.label}>
          <IF condition={title}>
            <TitleWrapper>
              <Title icon={title?.icon} text={title?.label} />
            </TitleWrapper>
          </IF>
          <IF condition={showCloseIcon === undefined || showCloseIcon === true}>
            <CloseModalIcon onClick={onClose}>
              <CloseIcon />
            </CloseModalIcon>
          </IF>
          {children}
        </ModalPaper>
      </Fade>
    </Modal>
  );
};
