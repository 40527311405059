import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  AccessTime as AccessTimeIcon,
  AccountCircle as AccountCircleIcon,
  DoneAll as DoneAllIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';
import styled from 'styled-components';
import {
  Box,
  Typography,
  MenuItem,
  ListItemIcon as _ListItemIcon,
} from '@material-ui/core';
import theme from '../theme';
import moment from 'moment-timezone';

const Seen = styled(DoneAllIcon)({
  color: theme.palette.primary.main,
  position: 'absolute',
  right: 0,
  bottom: 10,
  fontSize: '16px',
});
const Time = styled(Typography)({
  marginTop: theme.spacing(2),
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
});

const TimeIcon = styled(AccessTimeIcon)({
  marginRight: theme.spacing(1),
  fontSize: '16px',
});
const NotificationItem = styled(Box)({
  width: '400px',
  whiteSpace: 'normal',
  display: 'block',
  padding: theme.spacing(1),
  position: 'relative',
});
const NotificationTitle = styled(Typography)({
  fontWeight: 600,
});
const UnspecifiedTrip = ({ row, handleClick }) => {
  const { content } = row;
  return (
    <MenuItem key={row.id}>
      <NotificationItem onClick={(event) => handleClick(event, row)}>
        {/* Unpecified Trip of replaced with Ospecificerad resa av */}
        <NotificationTitle variant="body1" color="primary">
          Ospecificerad resa av {content.name} {content.lastname}
        </NotificationTitle>
        {/* Trip Started At replaced with Resan började kl */}
        <Typography variant="body2">
          Resan började kl:{' '}
          {moment(content.tripStartTime).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
        {/* Trip Ended At  replaced with Resan slutade kl */}
        <Typography variant="body2">
          Resan slutade kl:{' '}
          {moment(content.tripEndTime).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
        <Time variant="body2" color="primary">
          <TimeIcon />{' '}
          {moment(content.tripEndTime).format('YYYY-MM-DD [KL:] HH:mm')}
        </Time>
        <Seen />
      </NotificationItem>
    </MenuItem>
  );
};

export default UnspecifiedTrip;
