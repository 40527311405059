import axios from 'axios';
import { addToken, clearToken } from '../utils/jsonwebtoken';
import { refreshTokenRequest } from './requests';

const http = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SERVER_URL_DEV
      : process.env.REACT_APP_SERVER_URL_PROD,
  withCredentials: true,
});

http.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      try {
        if (error.response.data?.userStatus === false) {
          throw new Error(error);
        } else {
          originalConfig._retry = true;

          const response = await refreshTokenRequest();
          addToken(response.data.accessToken);
          originalConfig.headers[
            'authorization'
          ] = `Bearer ${response.data.accessToken}`;

          return http(originalConfig);
        }
      } catch (error) {
        clearToken();
        window.location.href = '/login';
        // history.push('/login');
      }
    }
  }
);

export default http;
