import React from 'react';
import { IconButton, Box, Drawer as SwipeableDrawer } from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import theme from '../../ui/theme';
import { MenuList } from '.';
import styled from 'styled-components';

const DrawerHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
});

export const Drawer = ({ handleDrawerClose, open }) => {
  return (
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerClose}
      style={{ zIndex: 10000002 }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <MenuList handleDrawerClose={handleDrawerClose} />
    </SwipeableDrawer>
  );
};
