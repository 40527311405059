import React from 'react';

import './Loader.scss';

export const Loader = ({ loading, children, height }) => {
  if (loading)
    return (
      <div
        className="spinner-small"
        style={{ height: height ? `${height}` : '250px' }}
      >
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    );

  return children;
};
