import { KeyboardTimePicker as _KeyboardTimePicker } from '@material-ui/pickers';
import { AccessTime as AccessTimeIcon } from '@material-ui/icons';
import styled from 'styled-components';

const KeyboardDatePicker = styled(_KeyboardTimePicker)({
  '& .MuiIconButton-root': {
    padding: '0 !important',
  },
});

export const Timepicker = ({ children, ...props }) => {
  return <KeyboardDatePicker keyboardIcon={<AccessTimeIcon />} {...props} />;
};
