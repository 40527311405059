export const weekDays = (el) => {
  let nameInSwedish = '';
  switch (el) {
    case 'Monday':
      nameInSwedish = 'Måndag';
      break;
    case 'Tuesday':
      nameInSwedish = 'Tisdag';
      break;
    case 'Wednesday':
      nameInSwedish = 'Onsdag';
      break;
    case 'Thursday':
      nameInSwedish = 'Torsdag';
      break;
    case 'Friday':
      nameInSwedish = 'Fredag';
      break;
    case 'Saturday':
      nameInSwedish = 'Lördag';
      break;
    case 'Sunday':
      nameInSwedish = 'Söndag';
      break;
    default:
      nameInSwedish = '';
  }
  return nameInSwedish;
};

export const roleInSwedenName = (role, name) => {
  let roleInSwedish = '';
  switch (name) {
    case `${role?.ADMIN?.name}`:
      roleInSwedish = 'ADMINISTRATION';
      break;
    case `${role?.DRIVER?.name}`:
      roleInSwedish = 'FÖRARE';
      break;
    case `${role?.SUPER_ADMIN?.name}`:
      roleInSwedish = 'SUPER_ADMIN';
      break;
    case `${role.COMPANY.name}`:
      roleInSwedish = 'FÖRETAG';
      break;
    case `${role.RESELLER.name}`:
      roleInSwedish = 'ÅTERFÖRSÄLJARE';
      break;
    default:
      roleInSwedish = '';
  }
  return roleInSwedish;
};

export const swedenRoleAuth = (role) => {
  if (role === 'ADMIN') {
    return 'ADMINISTRATION';
  } else if (role === 'DRIVER') {
    return 'FÖRARE';
  } else if (role === 'SUPER_ADMIN') {
    return 'SUPER_ADMIN';
  } else if (role === 'COMPANY') {
    return 'FÖRETAG';
  } else if (role === 'RESELLER') {
    return 'ÅTERFÖRSÄLJARE';
  } else {
    return '';
  }
};
export const reportTypeNameConversion = (role) => {
  if (role === 'combined') {
    return 'Kombinerat';
  } else if (role === 'business') {
    return 'Tjänsteresa';
  } else if (role === 'private') {
    return 'Privat resa';
  } else if (role === 'tollCost') {
    return 'Trängselskatt';
  } else {
    return '';
  }
  //tollCost
};
export const reportDurationConvertion = (role) => {
  if (role === 'daily') {
    return 'Dagligen';
  } else if (role === 'weekly') {
    return 'Veckovis';
  } else if (role === 'monthly') {
    return 'Månadsvis';
  } else {
    return '';
  }
};

// export const swedenTripType = (type) => {
//   if (role === 'ADMIN') {
//     return 'ADMINISTRATION';
//   } else if (role === 'DRIVER') {
//     return 'FÖRARE';
//   } else if (role === 'SUPER_ADMIN') {
//     return 'SUPER_ADMIN';
//   } else {
//     return '';
//   }
// };
