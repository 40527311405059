import { Box, Card } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const Wrapper = styled(Box)({
  padding: theme.spacing(1, 1, 1, 1),
});

const Content = styled(Card)({
  backgroundColor: '#ffffff',
  borderRadius: theme.spacing(0.5),
  minHeight: '100vh',
  padding: theme.spacing(0, 0, 3, 0),
});

export const Page = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};
