import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../ui/theme';

const TitleWrapper = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
});

const Text = styled('span')({
  marginLeft: theme.spacing(1),
});

export const Title = ({ icon, text }) => {
  return (
    <TitleWrapper variant="h6">
      {icon}
      <Text>{text}</Text>
    </TitleWrapper>
  );
};
