import React, { useState, useEffect, Fragment, useCallback } from 'react';
import clsx from 'clsx';
import {
  AccessTime as AccessTimeIcon,
  AccountCircle as AccountCircleIcon,
  DoneAll as DoneAllIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';
import LogoImg from '../../assets/images/logo.png';
import styled from 'styled-components';
import {
  makeStyles,
  Box,
  Toolbar,
  AppBar,
  IconButton,
  Badge,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon as _ListItemIcon,
  ListItemText,
  Chip,
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import theme from '../theme';
import { useAuthContext } from '../../store/auth';
import { IF } from 'utils/IF';
import {
  fetchAllInAppNotificationsRequest,
  fetchNotificationsCountRequest,
  updateNotificationStatusToSeenRequest,
} from '../../http/requests';
import { DriveEta as DriveEtaIcon } from '@material-ui/icons';
import { MuiModal } from '../../ui';
import { ChangeType } from './ChangeType';
import { swedenRoleAuth } from 'utils/swedishName';
import UnspecifiedTrip from './UnspecifiedTrip';
import VehicleTax from './VehicleTax';
import VehicleCheckup from './VehicleCheckup';
import {
  COMPANY_INVOICE,
  UNSPECIFIED_TRIP,
  UNSPECIFIED_TRIP_REMOVE_MULTIPLE,
  VEHICLE_CHECKUP,
  VEHICLE_TAX,
  notificationType,
} from 'utils/notificationsUtils';
import { COMPANY, SUPER_ADMIN } from 'utils/roles';
import { RoleBased } from 'utils/RoleBased';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompanyInvoice from './CompanyInvoice';
import useSocketEvents from 'hooks/useSocketEvents';

const drawerWidth = 320;

const LogoWrapper = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled('img')({
  width: '250px',
  '@media (max-width: 768px)': {
    width: '174px',
  },
  cursor: 'pointer',
});

const NotificationItem = styled(Box)({
  width: '400px',
  whiteSpace: 'normal',
  display: 'block',
  padding: theme.spacing(1),
  position: 'relative',
});

const NotificationPaginationWrapper = styled(Box)({
  margin: '10px',
  display: 'flex',
  alignItems: 'center',
});

const ListItemIcon = styled(_ListItemIcon)({
  minWidth: '35px',
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: 'none',
  },
}));

const NotificationTitle = styled(Typography)({
  fontWeight: 600,
});

const Time = styled(Typography)({
  marginTop: theme.spacing(2),
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
});

const TimeIcon = styled(AccessTimeIcon)({
  marginRight: theme.spacing(1),
  fontSize: '16px',
});

const Seen = styled(DoneAllIcon)({
  color: theme.palette.primary.main,
  position: 'absolute',
  right: 0,
  bottom: 10,
  fontSize: '16px',
});

export const Navbar = ({ open, handleDrawerOpen }) => {
  const classes = useStyles();

  const [notificationMenuAnchor, setNotificationMenuAnchor] = useState(null);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [selectedTripolog, setSelectedTripolog] = useState(-1);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [notificationCount, setNotificationCount] = useState(0);
  const { logout, user, role, isLoggedin, socket } = useAuthContext();
  const history = useHistory();

  const toggleModal = (id, row) => {
    setNoteModalOpen((prevState) => !prevState);
    setSelectedTripolog(row);
  };

  const handleNotificationMenuOpen = async (event) => {
    setNotificationMenuAnchor(event.currentTarget);
    try {
      const isNotificationMenuOpen = Boolean(event.currentTarget);

      if (isNotificationMenuOpen) {
        fetchNotifications();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (!isLoggedin) return;
    (async () => {
      try {
        const response = await fetchNotificationsCountRequest();
        setNotificationCount(Number(response.data.count));
      } catch (error) {
        // SnackbarUtils.error(error?.response?.data?.message);
      }
    })();
  }, [isLoggedin]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (!hasMore) return;
      const queryParams = new URLSearchParams();
      queryParams.append('page', page);
      queryParams.append('limit', limit);
      queryParams.append('order_by', 'id');
      queryParams.append('order_direction', 'ASC');
      const response = await fetchAllInAppNotificationsRequest(queryParams);
      const newData = response.data.notifications.data;
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...newData,
      ]);
      setPage((prevPage) => prevPage + 1);
      if (!response.data.notifications.nextPage) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  const notificationsListener = useCallback((data) => {
    console.log('data', data);
    setNotificationCount((prevCount) => prevCount + 1);
    setNotifications((prevNotifications) => [data, ...prevNotifications]);
  }, []);

  const removeMulitipleUnspecifiedTripListener = useCallback((data) => {
    if (data.length > 0) {
      setNotificationCount((prevCount) => prevCount - data.length);
      setNotifications((prevNotifications) => {
        let filteredNotifications = prevNotifications.filter(
          (item) => !data.includes(item.id)
        );
        return [...filteredNotifications];
      });
    }
  }, []);
  const eventHandlers = {
    [UNSPECIFIED_TRIP]: notificationsListener,
    [VEHICLE_CHECKUP]: notificationsListener,
    [VEHICLE_TAX]: notificationsListener,
    [COMPANY_INVOICE]: notificationsListener,
    [UNSPECIFIED_TRIP_REMOVE_MULTIPLE]: removeMulitipleUnspecifiedTripListener,
  };
  // useSocketEvents(socket, eventHandlers);

  const handleNotificationMenuClose = () => {
    setNotificationMenuAnchor(null);
    setPage(1);
    setHasMore(true);
    setNotifications([]);
  };
  const handleScrollMenu = (event) => {
    // console.log(event);
  };

  const handleAccountMenuOpen = (event) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchor(null);
  };

  const handleNavigateToAccount = () => {
    handleAccountMenuClose();
    history.push('/account');
  };

  const handleLogout = () => {
    handleAccountMenuClose();
    logout();
  };
  const handleClick = (e, row) => {
    toggleModal(row.id, row);
  };
  const handleChangeTripType = ({ note, tripType, id }) => {
    toggleModal();
  };
  const handleUpdatedList = ({ id }) => {
    setPage((prevPage) => prevPage - 1);
    const notificationCopy = [...notifications].filter((el) => el.id !== id);
    setNotifications(notificationCopy);
  };

  const handleNotificationSeen = async (row) => {
    try {
      const response = await updateNotificationStatusToSeenRequest({
        notificationId: row.id,
      });
      setNotificationCount(Number(notificationCount - 1));
      const notificationCopy = [...notifications].filter(
        (el) => el.id !== row.id
      );
      setNotifications(notificationCopy);
    } catch (error) {
      // SnackbarUtils.error(error?.response?.data?.message);
    }
  };
  const handleClickInvoice = (e, row) => {
    handleNotificationSeen(row);

    const infoId = row?.content?.reminderSettingId;
    history.push(`/edit-note/${infoId}`);
  };

  const isNotificationMenuOpen = Boolean(notificationMenuAnchor);
  const isAccountMenuOpen = Boolean(accountMenuAnchor);

  const notificationMenuId = 'primary-notification-menu';
  const notificationMenuListId = 'primary-notification-menu-list';
  const accountMenuId = 'primary-account-menu';
  const renderNotificationMenu = (
    <>
      <Menu
        anchorEl={notificationMenuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={notificationMenuId}
        open={isNotificationMenuOpen}
        onClose={handleNotificationMenuClose}
        getContentAnchorEl={null}
      >
        <InfiniteScroll
          dataLength={notifications.length} //This is important field to render the next data
          next={fetchNotifications}
          hasMore={hasMore}
          // loader={<h4>Loading...</h4>}
          // endMessage={<h4>It is all, nothing more</h4>}
          scrollableTarget={notificationMenuListId}
        >
          <MenuList
            id={notificationMenuListId}
            style={{
              minHeight: '0px',
              maxHeight: '300px',
              overflowY: 'scroll',
            }}
          >
            {notifications &&
              notifications.map((row) => (
                <div key={row.id}>
                  <IF condition={row.type == notificationType.vehicleCheckup}>
                    <RoleBased COMPANY={COMPANY}>
                      <VehicleCheckup key={row.id} row={row} />
                    </RoleBased>
                  </IF>
                  <IF condition={row.type == notificationType.unspecifiedTrip}>
                    <RoleBased COMPANY={COMPANY}>
                      <UnspecifiedTrip
                        key={row.id}
                        row={row}
                        handleClick={handleClick}
                      />
                    </RoleBased>
                  </IF>
                  <IF condition={row.type == notificationType.vehicleTax}>
                    <RoleBased COMPANY={COMPANY}>
                      <VehicleTax key={row.id} row={row} />
                    </RoleBased>
                  </IF>
                  <IF condition={row.type == notificationType.companyInvoice}>
                    <RoleBased SUPER_ADMIN={SUPER_ADMIN}>
                      <CompanyInvoice
                        key={row.id}
                        row={row}
                        handleClickInvoice={handleClickInvoice}
                      />
                    </RoleBased>
                  </IF>
                </div>
              ))}
          </MenuList>
        </InfiniteScroll>
        {notifications && notifications.length == 0 && (
          <MenuItem>
            <NotificationItem>
              <Typography variant="body2">Inga nya aviseringar</Typography>
            </NotificationItem>
          </MenuItem>
        )}
        <MuiModal
          open={noteModalOpen}
          onClose={toggleModal}
          width={'600px'}
          title={{
            label: 'Vänligen ändra typ av resa', //Please Change the type of trip replaced with Vänligen ändra typ av resa
            icon: <DriveEtaIcon color="primary" />,
          }}
        >
          <ChangeType
            selectedTripolog={selectedTripolog}
            handleChangeTripType={handleChangeTripType}
            handleUpdatedList={handleUpdatedList}
            onClose={toggleModal}
          />
        </MuiModal>
      </Menu>
    </>
  );
  const renderAccountMenu = (
    <Menu
      anchorEl={accountMenuAnchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={accountMenuId}
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
      getContentAnchorEl={null}
    >
      <MenuItem onClick={handleNavigateToAccount}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography variant="body2" gutterBottom>
                {user?.name} {user?.lastname}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {user?.email}
              </Typography>
              <Chip label={swedenRoleAuth(role)} color="primary" size="small" />
            </>
          }
        />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        {/* LogOut Name Is for Replaced Logga ut */}
        <ListItemText primary="Logga ut" />
      </MenuItem>
    </Menu>
  );
  // style={{ minHeight: 0 }}
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar style={{ minHeight: '48px' }}>
        <LogoWrapper>
          <NavLink to={isLoggedin === true ? '/' : '/login'}>
            {' '}
            <Logo src={LogoImg} alt="Trackmondo" />
          </NavLink>
        </LogoWrapper>
        <IF condition={isLoggedin}>
          <IconButton
            aria-label="show 17 new notifications"
            color="inherit"
            aria-controls={notificationMenuId}
            onClick={handleNotificationMenuOpen}
          >
            <Badge badgeContent={notificationCount} color="primary">
              {/* Notifications replaced with Notifikationer */}
              <Tooltip title="Notifikationer">
                <NotificationsIcon />
              </Tooltip>
            </Badge>
          </IconButton>
          <IconButton
            aria-label="show 17 new notifications"
            color="inherit"
            aria-controls={accountMenuId}
            onClick={handleAccountMenuOpen}
          >
            {/* Account replaced with Konto */}
            <Tooltip title="Konto">
              <AccountCircleIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </IF>
      </Toolbar>

      {renderNotificationMenu}
      {renderAccountMenu}
    </AppBar>
  );
};
