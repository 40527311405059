import {
  Box,
  TextField,
  InputAdornment,
  Grid,
  styled,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { FormControl } from '@mui/material';
import React from 'react';
import theme from 'ui/theme';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)({
  // marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  '@media(max-width: 768px)': {
    padding: theme.spacing(2),
  },
});
export const SearchFilter = ({ query, handleSearch }) => {
  const { t } = useTranslation();

  return (
    <Container item md={12} xs={12}>
      <Box p={1}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            variant="outlined"
            placeholder={t('action.search')}
            size="small"
            margin="dense"
            value={query}
            onChange={handleSearch}
            //fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
    </Container>
  );
};
// md={12} xs={12}
// p={1} pl={3}
//old code
// export const SearchFilter = ({ query, handleSearch }) => {
//   return (
//     <Box p={3} pb={1}>
//       <TextField
//         variant="outlined"
//         placeholder="Sök"
//         size="small"
//         margin="dense"
//         value={query}
//         onChange={handleSearch}
//         //fullWidth
//         InputProps={{
//           endAdornment: (
//             <InputAdornment position="end">
//               <SearchIcon />
//             </InputAdornment>
//           ),
//         }}
//       />
//     </Box>
//   );
// };
