import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { styled, useTheme } from '@material-ui/core/styles';
import { Tabs as _Tabs, Tab, Box, AppBar } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const Tabs = styled(_Tabs)({
  '& .MuiTabs-flexContainer': {
    boxShadow: 'none',
    '& .Mui-selected': {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
    },
    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const MuiTabs = ({ components, noPadding }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {components.map((el, index) => {
            return <Tab key={index} label={el.label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ boxShadow: 'none' }}
      >
        {components.map((el, index) => {
          return (
            <TabPanel
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
              style={{ padding: 0 }}
            >
              <Box p={noPadding ? 0 : 3}>{el.component}</Box>
            </TabPanel>
          );
        })}
      </SwipeableViews>
    </>
  );
};
