import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cc7e1a',
    },
    background: {
      default: '#fffffff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.6rem',
          height: '0.6rem',
        },
        '*::-webkit-scrollbar-track': {
          // '-webkit-box-shadow': 'inset 0 0 6px #cc7e1a'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#a8b7bf',
          outline: '1px solid #f1f1f1',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Bai Jamjuree',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
    },
  },
});

export default theme;
