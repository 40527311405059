import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Grid)({
  minHeight: '100vh',
  width: '100%',
});

const Page = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Page;
