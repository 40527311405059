import React from 'react';
import { useAuthContext } from '../../store/auth';
import { Spinner } from '../../ui';

const Status = ({ children }) => {
  const { isLoading } = useAuthContext();

  if (isLoading) {
    return <Spinner />;
  }

  return children;
};

export default Status;
