import { LazyLoader } from './lazy';
import { COMPANY, ADMIN, DRIVER, SUPER_ADMIN, RESELLER } from 'utils/roles';

const Users = LazyLoader(() => import('../pages/Users/Users'));
const Companies = LazyLoader(() => import('../pages/Companies/Companies'));
const AddReport = LazyLoader(() => import('../pages/Report/Report'));
const Tripologs = LazyLoader(() => import('../pages/Tripologs'));
const Account = LazyLoader(() => import('../pages/Account/Account'), 'Account');
const Map = LazyLoader(() => import('../pages/Map'));
const Home = LazyLoader(() => import('../pages/Home/Home'), 'Home');
const Vehicles = LazyLoader(() => import('../pages/Vehicles'), 'List');
const Login = LazyLoader(() => import('../pages/Login/Login'));
const ForgotPassword = LazyLoader(() =>
  import('../pages/ForgotPassword/ForgotPassword')
);
const Machines = LazyLoader(() => import('../pages/Machines/Machines'));
// const Geofence = LazyLoader(() => import('../pages/Geofence/Geofence'));
// const Routing = LazyLoader(() => import('../pages/Routing/Routing'));
const Places = LazyLoader(() => import('../pages/Places/Places'));
const AddCompany = LazyLoader(() => import('../pages/Companies/Add'), 'Add');
const EditCompany = LazyLoader(() => import('../pages/Companies/Edit'), 'Edit');
const AddCompanyNote = LazyLoader(
  () => import('../pages/Companies/AddNote'),
  'AddNote'
);
const EditCompanyNote = LazyLoader(
  () => import('../pages/Companies/EditNote'),
  'EditNote'
);
const AddUser = LazyLoader(() => import('../pages/Users/Add'), 'Add');
const EditUser = LazyLoader(() => import('../pages/Users/Edit'), 'Edit');
const AddVehicle = LazyLoader(() => import('../pages/Vehicles/Add'), 'Add');
const EditVehicle = LazyLoader(() => import('../pages/Vehicles/Edit'), 'Edit');
const Resellers = LazyLoader(() => import('../pages/Resellers/Resellers'));
const EditReseller = LazyLoader(
  () => import('../pages/Resellers/Edit'),
  'Edit'
);
const AddReseller = LazyLoader(() => import('../pages/Resellers/Add'), 'Add');
const Routing = LazyLoader(() => import('../pages/Routing'));
const Geofence = LazyLoader(() => import('../pages/Geofence'));
const Zonelogs = LazyLoader(() => import('../pages/Zonelogs/Zonelogs'));

export const routeListing = [
  {
    path: '/login',
    component: <Login />,
    auth: false,
    roles: {},
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
    auth: false,
    roles: {},
  },
  {
    path: '/add-report',
    component: <AddReport />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/tripologs',
    component: <Tripologs />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      DRIVER: DRIVER, // temporarirly
    },
  },
  {
    path: '/map',
    component: <Map />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      DRIVER: DRIVER,
    },
  },
  {
    path: '/vehicles',
    component: <Vehicles />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/machines',
    component: <Machines />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/places',
    component: <Places />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/add-user',
    component: <AddUser />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/edit-user/:userId',
    component: <EditUser />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/add-vehicle',
    component: <AddVehicle />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/edit-vehicle/:vehicleId',
    component: <EditVehicle />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/add-company',
    component: <AddCompany />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      RESELLER: RESELLER,
    },
  },
  {
    path: '/edit-company/:companyId',
    component: <EditCompany />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      RESELLER: RESELLER,
    },
  },
  {
    path: '/add-note/:companyId',
    component: <AddCompanyNote />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/edit-note/:infoId',
    component: <EditCompanyNote />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/users',
    component: <Users />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/companies',
    component: <Companies />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      RESELLER: RESELLER,
    },
  },
  {
    path: '/resellers',
    component: <Resellers />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/add-reseller',
    component: <AddReseller />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/edit-reseller/:resellerId',
    component: <EditReseller />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
    },
  },
  {
    path: '/account',
    component: <Account />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      DRIVER: DRIVER,
      RESELLER: RESELLER,
    },
  },
  {
    path: '/',
    component: <Home />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      DRIVER: DRIVER,
    },
  },
  {
    path: '/routing',
    component: <Routing />,
    auth: true,
    roles: {
      SUPER_ADMIN: SUPER_ADMIN,
      COMPANY: COMPANY,
      DRIVER: DRIVER,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/geofence',
    component: <Geofence />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
    },
  },
  {
    path: '/zonelogs',
    component: <Zonelogs />,
    auth: true,
    roles: {
      COMPANY: COMPANY,
      ADMIN: ADMIN,
      DRIVER: DRIVER,
    },
  },
];
