import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import LogoImg from 'assets/images/logo.png';
import theme from 'ui/theme';

const Layout = styled(Box)({
  boxSizing: 'border-box',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  flexDirection: 'column',
  textAlign: 'center',
});

const Logo = styled('img')({
  width: '600px',
  maxWidth: '100%',
  marginBottom: theme.spacing(3),
});

const ServerDown = () => {
  return (
    <Layout>
      <Logo src={LogoImg} alt="Trackmondo" />
      <Typography variant="h4" gutterBottom>
        Sorry, we're down for scheduled maintenance right now.
      </Typography>
      <Typography variant="body1">Please check back soon</Typography>
    </Layout>
  );
};

export default ServerDown;
